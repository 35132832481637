class SwipperCustom {
  constructor() {
    this._swipperFooter();
    this._swipperRelacionadas();
  }
  _swipperFooter() {

    var swiper = new Swiper(".swiperFooter", {
      slidesPerView: 3,
      spaceBetween: 30,
      grabCursor: true,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
      },
      breakpoints: {
        640: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 60,
        },
      },
    });

  }
  _swipperRelacionadas() {
    var swiper = new Swiper(".swiperRelacionadas", {
      slidesPerView: 1,
      spaceBetween: 0,
      grabCursor: true,
      pagination: {
        el: ".swiper-pagination",
      },
      
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1230: {
          slidesPerView: 3,
        },
      },
    });

  }

}