class ActiveSection {
    constructor() {
        this._stageSection();
        this._formplaceholder();

    }

    _stageSection() {
        if ($("body").hasClass("category-politica")) {
            $(".menu-item.politica").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-historia")) {
            $(".menu-item.historia").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-literatura")) {
            $(".menu-item.literatura").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-ciencias")) {
            $(".menu-item.ciencias").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-filosofia")) {
            $(".menu-item.filosofia").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-arte")) {
            $(".menu-item.arte").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-musica")) {
            $(".menu-item.musica").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-tecnologia")) {
            $(".menu-item.tecnologia").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-medio-ambiente")) {
            $(".menu-item.medioambiente").addClass("current-menu-item");
        }
        if ($("body").hasClass("category-linguistica")) {
            $(".menu-item.linguistica").addClass("current-menu-item");
        }
    }
    _formplaceholder() {
        jQuery(document).ready(function ($) {
            // Encuentra el campo de entrada por su ID
            const campo = $('footer #mailster-email-1');

            // Verifica si el campo existe
            if (campo.length) {
                // Agrega el marcador de posición al campo
                campo.attr('placeholder', 'Escribe tu email');
            }
        });
    }
}